import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Access/access01.jpg'
import AccessContents from './AccessContents'


function Work01contents() {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },
    imgSize: {
    //   backgroundImage: `url(${img01})`,
      height: '10px',
      width: '10px',
    },
    fontsize: {
      marginTop: '-3%',
      color: '#b3b3b3',
    },
    tableSize: {
      align: 'center',
      border: '1px solid #ccc',
      borderCollapse: 'collapse',
      width: '100%',
      height: '120%',
    },
    tableCss: {
      align: 'center',
      border: '1px solid #ccc',
      borderCollapse: 'collapse',
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript"  src="/jquery-3.3.1.js"></script>
        <script type="text/javascript"  src="/jquery.mCustomScrollbar.css"></script>
        <script type="text/javascript"  src="/jquery-migrate-1.4.1.js"></script>
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"></script>
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section className="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h3 style={style.margin}>
          {' '}
          信頼と肯定を誇り持って、価格創出を追求する企業
        </h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural">
                <dt>お問い合わせ</dt>
                <dd>
                  <a href="/access">お問い合わせ</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box">
              <div className="AccessSize">
                <AccessContents />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="top"></div>
    </div>
  )
}

export default Work01contents
