import React from "react"
import MainNav from "../../components/Main/MainNav/MainNav"
import MainNav_m from "../../components/Main/Header/MainNav_m"
import Footer from "../../components/Main/Footer/Footer"
import withStyles from "@material-ui/core/styles/withStyles"
import Access from "../../components/Access/Access"
import componentsStyle from "../../assets/jss/material-kit-react/views/components"

function Work(props) {
  return (
    <div style={{ backgroundColor: "white" }}>
        <MainNav />
        <MainNav_m />
        <Access />
        <Footer />
    </div>
  )
}

export default withStyles(componentsStyle)(Work)