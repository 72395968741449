import React, { useState } from 'react'
import bannerImg from '../../assets/img/Greetings/Banner02.jpg'
import img01 from '../../assets/img/Access/access02.png'
import img02 from '../../assets/img/Recruit04/must.png'
import img03 from '../../assets/img/Recruit04/kakunin.png'
import btn from '../../assets/img/Access/btn3.png'
import btn2 from '../../assets/img/Access/btn4.png'
import footimg02 from '../../assets/img/Recruit04/Recruit04_03.png'
import footimg03 from '../../assets/img/Recruit04/Recruit04_04.png'
import selectbtn from '../../assets/img/Recruit04/selectbtn.png'
import mustRed from '../../assets/img/Recruit04/mustRed.png'
import emailjs from 'emailjs-com';

export const ContactForm = () => {

  const handleMouseEnter = e => {
    e.target.src = btn2
  }
  const handleMouseLeave = e => {
    e.target.src = btn
  }

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },
    imgSize: {
      backgroundImage: `url(${img01})`,
      height: '15px',
      width: '35px',
    },
    // td1: {
    //   width: '20%',
    //   border: '1px solid #d3d3d3',
    //   fontWeight: 'bold',
    //   borderTop: '1px solid #d3d3d3',
    //   borderBottom: '1px solid #d3d3d3',
    //   fontWeight: 'bold',
    //   //   borderImage: 'linear-gradient(to right, #0083c5 0%, #0083c5 33%, #ec4a26 66%, #ec4a26 100%)',
    // },
    // td2: {
    //   border: '1px solid #d3d3d3',
    //   fontSize: '14px',
    //   paddingTop: '10px',
    //   paddingBottom: '10px',
    //   paddingLeft: '5px',
    //   paddingRight: '5px',
    fontWeight: 'bold',
    // },
    span: {
      fontColor: '#d3d3d3',
      align: 'center',
    },
    tableSize: {
      borderCollapse: 'collapse',
      width: '100%',
      height: '110%',
      border: '1px solid #ffffff',
    },
    tableCss: {
      borderCollapse: 'collapse',
      fontSize: '14px',
      paddingLeft: '30px',
      height: '60px',
      border: '1px solid #ffffff',
      fontWeight: 'bold',
    },
    tableTitle: {
      borderCollapse: 'collapse',
      fontSize: '2em',
      width: '18%',
      fontFamily: 'Noto Sans JP',
      color: '#008ad7',
      fontWeight: 'bold',
      paddingTop: '30px',
      paddingLeft: '80px',
      paddingBottom: '30px',
      fontWeight: 'bold',
    },
    td01: {
      borderCollapse: 'collapse',
      fontSize: '1.2em',
      paddingLeft: '25px',
      width: '20%',
      fontFamily: 'Noto Sans JP',
    },
    td02: {
      borderCollapse: 'collapse',
      fontSize: '0.9em',
      width: '13%',
      fontFamily: 'Noto Sans JP',
    },
    td03: {
      borderCollapse: 'collapse',
      fontSize: '1.1em',
      width: '20%',
      fontFamily: 'Noto Sans JP',
    },
    td04: {
      borderCollapse: 'collapse',
      fontSize: '1em',
      width: '5%',
      fontFamily: 'Noto Sans JP',
    },
    td05: {
      borderCollapse: 'collapse',
      fontSize: '1em',
      width: '5%',
      fontFamily: 'Noto Sans JP',
    },
    tdkakunin: {
      backgroundImage: 'url(' + img03 + ')',
    },
    textbox: {
      height: '30px',
      width: '370px',
      marginLeft: '-30px',
      border: 'none',
      backgroundColor: '#e6e6e6',
    },
    textboxS: {
      height: '30px',
      border: 'none',
      backgroundColor: '#e6e6e6',
    },
    textboxL: {
      width: '370px',
      height: '100px',
      marginLeft: '-30px',
      border: 'none',
      backgroundColor: '#e6e6e6',
      wordWrap: 'break-word',
    },
    btn: {
      width: '70%',
      height: '70%',
      backgroundSize: 'contain',
    },
    btn2: {
      width: '70%',
      height: '70%',
      backgroundSize: 'contain',
      
    },
    btnBg: {
      backgroundColor: '#ffffff',
    },
  }
  
  function sendEmail(e) {
    e.preventDefault();
    const answer = window.confirm('メールを送信しますか？')
    if (answer) {
    emailjs.sendForm('service_9j075z4', 'template_GHI', e.target, 'user_yqajUaJytOV1aEd7S5xHT')
      .then((result) => {
          console.log(result.text);
          alert('お問合せフォームを送信しました。\nありがとうございました。');

      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
    }
  }
  return (
    <div>
      <div className="content-box">
        <form onSubmit={sendEmail}>
          <br></br>
          <div id="table" className="contactWeb">
          <div className="item-wrapper">
            <img src={img01} className="accessTopimg" />
          </div>
          <div className="item-info-box2">
            <div id="table" className="contactWeb">
              <table className="tableSize2">
                <tr className="contacttr">
                  <td className="contactTitle2" align="center" colSpan="8">
                    ○　フォームからのお問い合わせ　○
                  </td>
                </tr>
                <tr className="contacttr">
                  <td className="contactTd01">お問い合わせ内容</td>
                  <td className="contactTd02" align="left">
                  <span style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}>
                    　必須　
                  </span>
                  </td>
                  <td className="contactTd03" align="left" colSpan="6">
                  <input type='hidden' name="title" value="お問い合わせメールが届きました。"></input>
                    <input
                      type="radio"
                      name="select"
                      value="採用・お問い合わせ"
                      required
                    />
                    　採用・お問い合わせ
                    <span>　　</span>
                    <input
                      type="radio"
                      name="select"
                      value="質問お問い合わせ"
                      required
                    />
                    　質問・お問い合わせ
                    <span>　　</span>
                    <input type="radio" name="select" value="その他" required />
                    　その他
                    <span>　</span>
                  </td>
                </tr>
                <tr className="contacttr">
                  <td className="contactTd01" align="left">
                    氏名
                  </td>
                  <td className="contactTd02" align="left">
                  <span style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}>
                    　必須　
                  </span>
                  </td>
                  <td className="contactTd04" align="left" colSpan="1">
                    姓
                  </td>
                  <td className="contactTd05" align="left" colSpan="2">
                    <input
                      type="text"
                      name="firstName"
                      className="contactTb-S"
                      required
                    ></input>
                  </td>
                  <td className="contactTd04" align="left" colSpan="1">
                    　名
                  </td>
                  <td className="contactTd05" align="left" colSpan="2">
                    <input
                      type="text"
                      name="lastName"
                      text="&nbsp;"
                      className="contactTb-S"
                      required
                    ></input>
                  </td>
                </tr>
                <tr className="contacttr">
                  <td className="contactTd01" align="left">
                    氏名・フリガナ
                  </td>
                  <td className="contactTd02" align="left">
                  <span style={{ backgroundColor: '#c7b299', color: 'white' ,fontSize:'80%'}}>
                    　必須　
                  </span>
                  </td>
                  <td className="contactTd04" align="left" colSpan="1">
                    姓
                  </td>
                  <td className="contactTd05" align="left" colSpan="2">
                    <input
                      type="text"
                      name="firstHuri"
                      className="contactTb-S"
                      required
                    ></input>
                  </td>
                  <td className="contactTd04" align="left" colSpan="1">
                    　名
                  </td>
                  <td className="contactTd05" align="left" colSpan="2">
                    <input
                      type="text"
                      name="lastHuri"
                      className="contactTb-S"
                      required
                    ></input>
                  </td>
                </tr>
                <tr className="contacttr">
                  <td className="contactTd01" align="left">
                    メールアドレス
                  </td>
                  <td className="contactTd02" align="left">
                  <span style={{ backgroundColor: '#c7b299', color: 'white' ,fontSize:'80%'}}>
                    　必須　
                  </span>
                  </td>
                  <td className="contactmail" align="left" colSpan="6">
                    <input
                      type="text"
                      name="email"
                      className="contactTextbox"
                      required
                    ></input>
                  </td>
                </tr>
                <tr className="contacttr">
                  <td className="contactTd01" align="left">
                    電話番号
                  </td>
                  <td className="contactTd02" align="left"></td>
                  <td className="contactmail" align="left" colSpan="6">
                    <input
                      type="text"
                      name="tel"
                      className="contactTextbox"
                    ></input>
                  </td>
                </tr>
                <tr className="contacttr">
                  <td className="contactTd01" align="left">
                    会社名
                  </td>
                  <td className="contactTd02" align="left"></td>
                  <td className="contactmail" align="left" colSpan="6">
                    <input type="hidden" name="conNameTitle" value="会社名　：　"></input>
                    <input
                      type="text"
                      name="comName"
                      className="contactTextbox"
                    ></input>
                  </td>
                </tr>
                <tr className="contacttr">
                  <td className="contactTd01" align="left">
                    部署名
                  </td>
                  <td className="contactTd02" align="left"></td>
                  <td className="contactmail" align="left" colSpan="6">
                  <input type="hidden" name="deNameTitle" value="部署名　：　"></input>
                    <input
                      type="text"
                      name="deName"
                      className="contactTextbox"
                    ></input>
                  </td>
                </tr>
                <tr className="contacttr">
                  <td className="contactTd01" align="left">
                    問い合わせ内容
                  </td>
                  <td className="contactTd02" align="left">
                  <span style={{ backgroundColor: '#c7b299', color: 'white' ,fontSize:'80%'}}>
                    　必須　
                  </span>
                  </td>
                  <td className="contactmail" align="left" colSpan="6">
                    <textarea
                      className="contactTb-L"
                      name="message"
                      style={{ resize: 'none' }}
                      required
                    ></textarea>
                  </td>
                </tr>

                <tr align="center">
                  <td align="center" colSpan="8">
                    <button type="submit" style={style.btnBg}>
                      <img src={btn} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={style.btn} alt="" />
                    </button>
                  </td>
                </tr>
              </table>
            </div>
            </div>
        
          </div>
         </form>
        <form onSubmit={sendEmail}>
          <br></br>
          <div className="contactMo">
          <div className="item-wrapper">
            <img src={img01} className="accessTopimg" />
          </div>
          <div className="item-info-box2">
            <div id="table" className="contactMo">
              <span className="accessmo-title">
                ○　フォームからのお問い合わせ　○
              </span>
              <br></br>
              <br></br>

              <span className="contactmo-td01">
                お問い合わせ内容　<span style={{ backgroundColor: '#c7b299', color: 'white' ,fontSize:'80%'}}>
                    　必須　
                  </span>
              </span>
              <div className="contactmo-Td03">
              <input type='hidden' name="title" value="お問い合わせメールが届きました。"></input>
                <input
                  type="radio"
                  name="select"
                  value="採用・お問い合わせ"
                  required
                />
                　採用・お問い合わせ
                <span>　　</span>
                <input
                  type="radio"
                  name="select"
                  value="質問お問い合わせ"
                  required
                />
                　質問・お問い合わせ
                <br></br>
                <input type="radio" name="select" value="その他" required />
                　その他
                <span>　</span>
              </div>

              <span className="contactmo-td01">
                氏名　<span style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}>
                    　必須　
                  </span>
              </span>
              <div className="contactmo-Td03">
                姓　
                <input
                  type="text"
                  name="firstName"
                  className="contactTb-S"
                  required
                ></input>
                名
                <input
                  type="text"
                  name="lastName"
                  className="contactTb-S"
                  required
                ></input>
              </div>
              <span className="contactmo-td01">
                氏名・フリガナ　<span style={{ backgroundColor: '#c7b299', color: 'white' ,fontSize:'80%'}}>
                    　必須　
                  </span>
              </span>
              <div className="contactmo-Td03">
                姓　
                <input
                  type="text"
                  name="firstHuri"
                  className="contactTb-S"
                  required
                ></input>
                名
                <input
                  type="text"
                  name="lastHuri"
                  className="contactTb-S"
                  required
                ></input>
              </div>
              <span className="contactmo-td01">
                メールアドレス　<span style={{ backgroundColor: '#c7b299', color: 'white' ,fontSize:'80%'}}>
                    　必須　
                  </span>
              </span>
              <div className="contactmo-Td03">
                <input
                  type="text"
                  name="email"
                  className="contactTb-S"
                  required
                ></input>
              </div>
              <span className="contactmo-td01">
                電話番号　
              </span>
              <div className="contactmo-Td03">
                <input
                  type="text"
                  name="tel"
                  className="contactTb-S"
                  required
                ></input>
              </div>
              <span className="contactmo-td01">
              会社名　
              </span>
              <div className="contactmo-Td03">
                <input
                  type="text"
                  name="comName"
                  className="contactTb-S"
                  required
                ></input>
              </div>
              <span className="contactmo-td01">
              部署名
              </span>
              <div className="contactmo-Td03">
                <input
                  type="text"
                  name="deName"
                  className="contactTb-S"
                  required
                ></input>
              </div>
              <span className="contactmo-td01">
                問い合わせ内容　<span style={{ backgroundColor: '#c7b299', color: 'white' ,fontSize:'80%'}}>
                    　必須　
                  </span>
              </span>
              <div className="contactmo-Td03">
                <textarea className="contactTb-L" name="message"  style={{ resize: 'none' }}></textarea>
              </div>
             <div
                style={{
                  paddingLeft: '15px',
                  paddingRight: '15px',
                }}
              >
                <div style={{ paddingTop: '0px' }}>
                  <button type="submit" style={style.btnBg}>
                    <img
                      src={btn}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{ width: '40%', height: '40%' }}
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
            </div>

          </div>
        
            </form>
            
         
      </div>
    </div>
  )
}

export default ContactForm
